import React from "react";

export default (props: any) => (
  <div {...props}>
    <h4>Datenschutzerklärung</h4>
    <p>
      Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
      EU-Datenschutzgrundverordnung (DSGVO), ist:
    </p>
    <p>
      Pizza-Heim-Service<br></br>
      Offenbacher Str. 14<br></br>
      63165 Mühlheim am Main<br></br>
    </p>
    <h4>Ihre Betroffenenrechte</h4>
    <p>
      Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können
      Sie jederzeit folgende Rechte ausüben:
    </p>
    <ul>
      <li>
        Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung
        (Art. 15 DSGVO),
      </li>
      <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
      <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
      <li>
        Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
        gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
      </li>
      <li>
        Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO)
        und
      </li>
      <li>
        Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt
        haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
      </li>
    </ul>
    <p>
      Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit
      mit Wirkung für die Zukunft widerrufen.
    </p>
    <p>
      Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde
      wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres
      Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige
      Behörde.
    </p>
    <p>
      Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit
      Anschrift finden Sie unter:{" "}
      <a
        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
        target="_blank"
        rel="nofollow noopener"
      >
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
      </a>
      .
    </p>
    <p />
    <h4>Verwendung von Scriptbibliotheken (Google Webfonts)</h4>
    <h5>Art und Zweck der Verarbeitung:</h5>
    <p>
      Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
      darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der
      Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
      nachfolgend „Google“) zur Darstellung von Schriften.
    </p>
    <p>
      Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier:{" "}
      <a href="https://www.google.com/policies/privacy/">
        https://www.google.com/policies/privacy/
      </a>
    </p>
    <h5>Rechtsgrundlage:</h5>
    <p>
      Rechtsgrundlage für die Einbindung von Google Webfonts und dem damit
      verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1
      lit. a DSGVO).{" "}
    </p>
    <h5>Empfänger:</h5>
    <p>
      Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst
      automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es
      theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu
      welchen Zwecken – dass der Betreiber in diesem Fall Google Daten erhebt.
    </p>
    <h5>Speicherdauer:</h5>
    <p>
      Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google
      Webfonts.
    </p>
    <p>
      Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
      <a href="https://developers.google.com/fonts/faq">
        https://developers.google.com/fonts/faq
      </a>{" "}
      und in der Datenschutzerklärung von Google:{" "}
      <a href="https://www.google.com/policies/privacy/">
        https://www.google.com/policies/privacy/
      </a>
      .
    </p>
    <h5>Drittlandtransfer:</h5>
    <p>
      Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy
      Shield unterworfen{" "}
      <a href="https://www.privacyshield.gov/EU-US-Framework">
        https://www.privacyshield.gov/EU-US-Framework
      </a>
      .
    </p>
    <h5>Bereitstellung vorgeschrieben oder erforderlich:</h5>
    <p>
      Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich, noch
      vertraglich vorgeschrieben. Allerdings kann ggfs. die korrekte Darstellung
      der Inhalte durch Standardschriften nicht möglich sein.
    </p>
    <h5>Widerruf der Einwilligung:</h5>
    <p>
      Zur Darstellung der Inhalte wird regelmäßig die Programmiersprache
      JavaScript verwendet. Sie können der Datenverarbeitung daher
      widersprechen, indem Sie die Ausführung von JavaScript in Ihrem Browser
      deaktivieren oder einen JavaScript-Blocker installieren. Bitte beachten
      Sie, dass es hierdurch zu Funktionseinschränkungen auf der Website kommen
      kann.
    </p>
    <p />
    <h4>Verwendung von Google Maps</h4>
    <h5>Art und Zweck der Verarbeitung:</h5>
    <p>
      Auf dieser Webseite nutzen wir das Angebot von Google Maps. Google Maps
      wird von Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
      USA (nachfolgend „Google“) betrieben. Dadurch können wir Ihnen interaktive
      Karten direkt in der Webseite anzeigen und ermöglichen Ihnen die
      komfortable Nutzung der Karten-Funktion.
    </p>
    <p>
      Nähere Informationen über die Datenverarbeitung durch Google können Sie{" "}
      <a href="http://www.google.com/privacypolicy.html">
        den Google-Datenschutzhinweisen
      </a>{" "}
      entnehmen. Dort können Sie im Datenschutzcenter auch Ihre persönlichen
      Datenschutz-Einstellungen verändern.
    </p>
    <p>
      Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang
      mit Google-Produkten
      <a href="http://www.dataliberation.org/"> finden Sie hier</a>.
    </p>
    <h5>Rechtsgrundlage:</h5>
    <p>
      Rechtsgrundlage für die Einbindung von Google Maps und dem damit
      verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1
      lit. a DSGVO).
    </p>
    <h5>Empfänger:</h5>
    <p>
      Durch den Besuch der Webseite erhält Google Informationen, dass Sie die
      entsprechende Unterseite unserer Webseite aufgerufen haben. Dies erfolgt
      unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie
      eingeloggt sind, oder ob keine Nutzerkonto besteht. Wenn Sie bei Google
      eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet.
    </p>
    <p>
      Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht wünschen, müssen
      Sie sich vor Aktivierung des Buttons bei Google ausloggen. Google
      speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der
      Werbung, Marktforschung und/oder bedarfsgerechter Gestaltung seiner
      Webseite. Eine solche Auswertung erfolgt insbesondere (selbst für nicht
      eingeloggte Nutzer) zur Erbringung bedarfsgerechter Werbung und um andere
      Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Webseite
      zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung
      dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten
      müssen.
    </p>
    <h5>Speicherdauer:</h5>
    <p>
      Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google
      Maps.
    </p>
    <h5>Drittlandtransfer:</h5>
    <p>
      Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy
      Shield unterworfen{" "}
      <a href="https://www.privacyshield.gov/EU-US-Framework">
        https://www.privacyshield.gov/EU-US-Framework
      </a>
      .
    </p>
    <h5>Widerruf der Einwilligung:</h5>
    <p>
      Wenn Sie nicht möchten, dass Google über unseren Internetauftritt Daten
      über Sie erhebt, verarbeitet oder nutzt, können Sie in Ihrem
      Browsereinstellungen JavaScript deaktivieren. In diesem Fall können Sie
      unsere Webseite jedoch nicht oder nur eingeschränkt nutzen.
    </p>
    <h5>Bereitstellung vorgeschrieben oder erforderlich:</h5>
    <p>
      Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
      allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden,
      kann es hierdurch zu Funktionseinschränkungen auf der Website kommen.
    </p>
    <p />
    <h4>Google AdWords</h4>
    <h5>Art und Zweck der Verarbeitung:</h5>
    <p>
      Unsere Webseite nutzt das Google Conversion-Tracking.
      Betreibergesellschaft der Dienste von Google AdWords ist die Google LLC,
      1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Sind Sie über
      eine von Google geschaltete Anzeige auf unsere Webseite gelangt, wird von
      Google Adwords ein Cookie auf Ihrem Rechner gesetzt. Das Cookie für
      Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine von Google
      geschaltete Anzeige klickt.
    </p>
    <p>
      Besucht der Nutzer bestimmte Seiten unserer Website und das Cookie ist
      noch nicht abgelaufen, können wir und Google erkennen, dass der Nutzer auf
      die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jeder
      Google AdWords-Kunde erhält ein anderes Cookie. Cookies können somit nicht
      über die Websites von AdWords-Kunden nachverfolgt werden. Die mithilfe des
      Conversion-Cookies eingeholten Informationen dienen dazu,
      Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für
      Conversion-Tracking entschieden haben. Die Kunden erfahren die
      Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer
      mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden.
      Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich
      identifizieren lassen.
    </p>
    <h5>Rechtsgrundlage:</h5>
    <p>
      Rechtsgrundlage für die Einbindung von Google AdWords und dem damit
      verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1
      lit. a DSGVO).
    </p>
    <h5>Empfänger:</h5>
    <p>
      Bei jedem Besuch unsere Webseite werden personenbezogene Daten,
      einschließlich Ihrer IP-Adresse an Google in die USA übertragen. Diese
      personenbezogenen Daten werden durch Google gespeichert. Google gibt diese
      über das technische Verfahren erhobenen personenbezogenen Daten unter
      Umständen an Dritte weiter.
    </p>
    <p>
      Unser Unternehmen enthält keine Informationen von Google, mittels derer
      die betroffene Person identifiziert werden könnte.
    </p>
    <h5>Speicherdauer:</h5>
    <p>
      Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der
      persönlichen Identifizierung.
    </p>
    <h5>Drittlandtransfer:</h5>
    <p>
      Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy
      Shield unterworfen{" "}
      <a href="https://www.privacyshield.gov/EU-US-Framework">
        https://www.privacyshield.gov/EU-US-Framework
      </a>
      .
    </p>
    <h5>Widerruf der Einwilligung:</h5>
    <p>
      Möchten Sie nicht am Tracking teilnehmen, können Sie das hierfür
      erforderliche Setzen eines Cookies ablehnen – etwa per
      Browser-Einstellung, die das automatische Setzen von Cookies generell
      deaktiviert oder Ihren Browser so einstellen, dass Cookies von der Domain
      „googleleadservices.com“ blockiert werden.
    </p>
    <p>
      Bitte beachten Sie, dass Sie die Opt-out-Cookies nicht löschen dürfen,
      solange Sie keine Aufzeichnung von Messdaten wünschen. Haben Sie alle Ihre
      Cookies im Browser gelöscht, müssen Sie das jeweilige Opt-out Cookie
      erneut setzen.
    </p>
    <h5>Bereitstellung vorgeschrieben oder erforderlich:</h5>
    <p>
      Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
      allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden,
      kann es hierdurch zu Funktionseinschränkungen auf der Website kommen.
    </p>
    <p />
    <h4>SSL-Verschlüsselung</h4>
    <p>
      Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden
      wir dem aktuellen Stand der Technik entsprechende
      Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
    </p>
    <p />
    <h4>Änderung unserer Datenschutzbestimmungen</h4>
    <p>
      Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
      stets den aktuellen rechtlichen Anforderungen entspricht oder um
      Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B.
      bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die
      neue Datenschutzerklärung.
    </p>
    <h4>Fragen an den Datenschutzbeauftragten</h4>
    <p>
      Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail
      oder wenden Sie sich direkt an die für den Datenschutz verantwortliche
      Person in unserer Organisation:
    </p>
    <p>
      Pizza-Heim-Service<br></br>
      Offenbacher Str. 14<br></br>
      63165 Mühlheim am Main<br></br>
    </p>
    <p>
      <em>
        Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den
        Experten für{" "}
        <a
          href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
          target="_blank"
          rel="noopener"
        >
          externe Datenschutzbeauftragte
        </a>{" "}
        (Version #2019-04-10).
      </em>
    </p>
  </div>
);
