const menu = {
  dishes: {
    pizza: [
      {
        name: "Margherita",
        prices: [9.0, 16.0, 32.0],
        id: 1,
        ingredients: ["Tomaten", "Käse"],
      },
      {
        name: "Salami",
        prices: [9.0, 16.0, 32.0],
        id: 2,
        ingredients: ["Tomaten", "Käse", "Salami"],
      },
      {
        name: "Prosciutto",
        prices: [9.0, 16.0, 32.0],
        id: 3,
        ingredients: ["Tomaten", "Käse", "Schinken"],
      },
      {
        name: "Funghi",
        prices: [9.0, 16.0, 32.0],
        id: 4,
        ingredients: ["Tomaten", "Käse", "Champignons"],
      },
      {
        name: "Salamino Picante",
        prices: [9.0, 16.0, 32.0],
        id: 5,
        ingredients: ["Tomaten", "Käse", "Peperoniwurst"],
      },
      {
        name: "Napoli",
        prices: [9.5, 16.5, 33.0],
        id: 6,
        ingredients: ["Tomaten", "Käse", "Salami", "Champignons"],
      },
      {
        name: "Treviso",
        prices: [9.5, 16.5, 33.0],
        id: 7,
        ingredients: ["Tomaten", "Käse", "Schinken", "Champignons"],
      },
      {
        name: "Gabriele",
        prices: [10.0, 18.5, 37.0],
        id: 8,
        ingredients: ["Tomaten", "Käse", "Salami", "Pommes"],
        new: true,
      },
      {
        name: "Romana",
        prices: [9.5, 17.0, 34.0],
        id: 9,
        ingredients: ["Tomaten", "Käse", "Salami", "Schinken", "Champignons"],
      },
      {
        name: "Mista",
        prices: [9.5, 17.0, 34.0],
        id: 10,
        ingredients: ["Tomaten", "Käse", "Salami", "Schinken", "Peperoniwurst"],
      },
      {
        name: "Michelangelo",
        prices: [10.0, 18.0, 36.0],
        id: 11,
        ingredients: [
          "Tomaten",
          "Käse",
          "Schinken",
          "Peperoniwurst",
          "Champignons",
          "Paprika",
        ],
      },
      {
        name: "Vier Käse",
        prices: [10.0, 18.0, 36.0],
        id: 12,
        ingredients: [
          "Tomaten",
          "Mozzarella",
          "Gorgonzola",
          "Parmesan",
          "Gruviera",
        ],
      },
      {
        name: "Speciale",
        prices: [10.0, 18.0, 36.0],
        id: 13,
        ingredients: ["Tomaten", "Käse", "Salami", "Peperoniwurst", "Zwiebeln"],
      },
      {
        name: "Vier Jahreszeiten",
        prices: [10.0, 18.0, 36.0],
        id: 14,
        ingredients: [
          "Tomaten",
          "Käse",
          "Schinken",
          "Artischocken",
          "Paprika",
          "Champignons",
        ],
      },
      {
        name: "Fuoco",
        prices: [10.5, 18.5, 37.0],
        id: 15,
        ingredients: [
          "Tomaten",
          "Käse",
          "Salami",
          "Peperoniwurst",
          "Paprikaschoten",
          "Spiegelei",
          "Tomatenscheiben",
          "(scharf)",
        ],
      },
      {
        name: "Verde",
        prices: [11.0, 18.5, 37.0],
        id: 16,
        ingredients: [
          "Käse",
          "Broccoli",
          "Spinat",
          "Paprika",
          "Champignons",
          "OHNE Tomaten",
        ],
        new: true,
      },
      {
        name: "Hawaii",
        prices: [9.5, 17.0, 34.0],
        id: 17,
        ingredients: ["Tomaten", "Käse", "Schinken", "Ananas"],
      },
      {
        name: "Suprema",
        prices: [10.5, 18.5, 37.0],
        id: 18,
        ingredients: [
          "Tomaten",
          "Käse",
          "Salami",
          "Schinken",
          "Peperoniwurst",
          "Zwiebeln",
          "Champignons",
          "Peperonischoten",
        ],
      },
      {
        name: "Salvatore",
        prices: [10.0, 18.0, 36.0],
        id: 19,
        ingredients: [
          "Tomaten",
          "Käse",
          "Salami",
          "Peperoniwurst",
          "Zwiebeln",
          "Champignons",
        ],
      },
      {
        name: "Tonno",
        prices: [10.0, 18.0, 36.0],
        id: 21,
        ingredients: [
          "Tomaten",
          "Käse",
          "Thunfisch",
          "Zwiebeln",
          "Champignons",
        ],
      },
      {
        name: "Superiore",
        prices: [10.5, 18.5, 37.0],
        id: 22,
        ingredients: [
          "Tomaten",
          "Käse",
          "Salami",
          "Schinken",
          "Champignons",
          "Zwiebeln",
          "Paprika",
        ],
      },
      {
        name: "Mozzarella",
        prices: [10.5, 18.5, 37.0],
        id: 23,
        ingredients: [
          "Tomaten",
          "Käse",
          "Tomatenscheiben",
          "Mozzarella",
          "Basilikum",
        ],
      },
      {
        name: "Casa",
        prices: [10.5, 18.5, 37.0],
        id: 24,
        ingredients: ["Tomaten", "Käse", "Schinken", "Broccoli", "Gorgonzola"],
      },
      {
        name: "Calzone",
        prices: [10.0, 18.0, 36.0],
        id: 25,
        ingredients: [
          "(Geklappt)",
          "Tomaten (doppelt)",
          "Käse (doppelt)",
          "Salami",
          "Schinken",
          "Champignons",
        ],
      },
      {
        name: "Popey",
        prices: [10.0, 18.0, 36.0],
        id: 26,
        ingredients: [
          "Tomaten",
          "Käse",
          "Schinken",
          "Spinat",
          "Zwiebeln",
          "Spiegelei",
        ],
      },
    ],
    pinsa: [
      {
        name: "Margherita",
        price: 12.5,
        id: 101,
        ingredients: ["Tomaten", "Mozzarella"],
      },
      {
        name: "Calabrese",
        price: 12.5,
        id: 102,
        ingredients: [
          "Tomaten",
          "Käse",
          "Spianata Calabrese",
          "frische Champignons",
        ],
      },
      {
        name: "Bianca",
        price: 13.5,
        id: 103,
        ingredients: ["Käse", "Thunfisch", "Zwiebeln", "OHNE Tomaten"],
      },
      {
        name: "Mista",
        price: 13.5,
        id: 104,
        ingredients: ["Tomaten, Käse, Salami, Schinken, Champignons, Zwiebeln"],
      },
      {
        name: "Ti Amo",
        price: 9.5,
        id: 105,
        ingredients: ["Nutella"],
      },
      {
        name: "Mortadella",
        price: 13.5,
        id: 106,
        ingredients: ["Mozzarella", "Mortadella", "OHNE Tomaten"],
      },
    ],
    isoletta: [
      {
        name: "Tagliatelle Artischocken",
        price: 14.5,
        id: 300,
        ingredients: [
          "Mit kleinen Scampi in einer leichten Tomatensauce mit Weisswein und Zwiebeln",
        ],
      },
      {
        name: "Tagliatelle mit frischem Lachs",
        price: 13.5,
        id: 301,
        ingredients: ["Mit frischen Cocktailtomaten", "Zwiebeln", "Sahne"],
      },
      {
        name: "Spaghetti Calabrese",
        price: 12.5,
        id: 302,
        ingredients: [
          "Oliven",
          "Sardellen",
          "Knoblauch",
          "Tomatensauce",
          "leicht Scharf",
        ],
      },
      {
        name: "Rigatoni mit frischer Salsiccia",
        price: 14.5,
        id: 303,
        ingredients: ["Broccol", "Cocktailtomaten", "Weisswein", "Zwiebeln"],
      },
      {
        name: "Rigatoni mit Hähnchenbruststreifen",
        price: 13.5,
        id: 304,
        ingredients: [
          "verfeinert mit Weisswein Tomatensauce und Zwiebeln (leicht Scharf)",
        ],
      },
      {
        name: "Ravioli gefüllt mit Steinpilzen",
        price: 13.5,
        id: 305,
        ingredients: ["in einer leichten Tomatensauce"],
      },
    ],
    meat: [
      {
        name: "Schweinemedaillons mit Pilzen",
        price: 15.5,
        id: 310,
        ingredients: ["mit Sahne und Weisswein"],
      },
      {
        name: "Schweinemedaillons Gorgonzola",
        price: 15.5,
        id: 311,
        ingredients: ["mit Gorgonzolasauce"],
      },
      {
        name: "Schweinemedaillons",
        price: 15.5,
        id: 312,
        ingredients: ["mit Grünerpfeffersauce"],
      },
      {
        name: "Rumpsteak vom Grill",
        price: 22.5,
        id: 320,
        ingredients: ["mit Grünerpfeffersauce", "Pommes", "kl. Salat"],
      },
      {
        name: "Rumpsteak Gorogonzola",
        price: 22.5,
        id: 321,
        ingredients: ["Vom Grill mit Gorgonzolasauce", "Pommes", "kl. Salat"],
      },
    ],
    fish: [
      {
        name: "Lachs vom Grill",
        price: 15.5,
        id: 330,
        ingredients: ["in einer Senfsahnsauce"],
      },
    ],
    salad: [
      {
        name: "Gemischter Salat",
        price: 7.0,
        id: 30,
        ingredients: ["Blattsalate", "Gurken", "Zwiebeln", "Tomaten"],
      },
      {
        name: "Tomatensalat",
        price: 8.0,
        id: 31,
        ingredients: ["Blattsalate", "Tomaten", "Zwiebeln"],
      },
      {
        name: "Tomatensalat Spezial",
        price: 10.0,
        id: 32,
        ingredients: [
          "Blattsalate",
          "Tomaten",
          "Zwiebeln",
          "Thunfisch",
          "Ei",
          "Kapern",
        ],
      },
      {
        name: "Bauernsalat",
        price: 9.5,
        id: 33,
        ingredients: [
          "Blattsalate",
          "Gurken",
          "Zwiebeln",
          "Tomaten",
          "Mais",
          "Paprika",
        ],
      },
      {
        name: "Mozzarella Caprese",
        price: 9.5,
        id: 34,
        ingredients: [
          "Blattsalate",
          "Tomaten",
          "Mozzarella",
          "Basilikum",
          "Essig+Öl",
        ],
      },
      {
        name: "Italienischer Salat",
        price: 10.5,
        id: 35,
        ingredients: [
          "Blattsalate",
          "Gurken",
          "Zwiebeln",
          "Tomaten",
          "Mais",
          "Käse",
          "Schinken",
          "Thunfisch",
          "Ei",
        ],
      },
      {
        name: "Capriciosa Salat",
        price: 10.5,
        id: 36,
        ingredients: [
          "Blattsalate",
          "Gurken",
          "Zwiebeln",
          "Tomaten",
          "Mais",
          "Käse",
          "Schinken",
          "Shrimps",
          "Ei",
        ],
      },
      {
        name: "Schafskäsesalat",
        price: 10.5,
        id: 37,
        ingredients: [
          "Blattsalate",
          "Gurken",
          "Zwiebeln",
          "Tomaten",
          "Mais",
          "Paprika",
          "Weichkäse",
          "Peperoni",
        ],
      },
      {
        name: "Tacchino Salat",
        price: 10.5,
        id: 38,
        ingredients: [
          "Blattsalate",
          "Gurken",
          "Zwiebeln",
          "Tomaten",
          "Mais",
          "Paprika",
          "Putenbruststreifen",
        ],
      },
    ],
    noodles: [
      {
        name: "Lasagne",
        price: 10.0,
        id: 400,
        ingredients: [
          "feine Schicht Nudeln, Fleisch- u. Bechamelsauce",
          "Käse (überbacken)",
        ],
      },
      {
        name: "Lasagne Spezial",
        price: 11.0,
        id: 401,
        ingredients: [
          "feine Schicht Nudeln, Fleisch- u. Bechamelsauce",
          "Champignons",
          "Broccoli",
          "Käse (überbacken)",
        ],
      },
      {
        name: "Combinazione",
        price: 10.0,
        id: 402,
        ingredients: [
          "Spaghettin",
          "Riggatoni",
          "Tortellini",
          "Fleisch- u. Sahnesauce",
          "Käse (überbacken)",
        ],
      },
      {
        name: "Gnocchi al forno",
        price: 10.0,
        id: 403,
        ingredients: [
          "Ital. Kartoffelnudeln",
          "Fleisch- u. Sahnesauce",
          "Käse (überbacken)",
        ],
      },
      {
        name: "Rigatoni al forno",
        price: 10.5,
        id: 404,
        ingredients: [
          "Röhrennudeln",
          "Tomaten- u. Sahnesauce",
          "Schinken",
          "Erbsen",
          "Ei",
          "Käse (überbacken)",
        ],
      },
      {
        name: "Tortellini al forno",
        price: 11.0,
        id: 405,
        ingredients: [
          "Gefüllte Teigtaschen",
          "Fleisch- u. Sahnesauce",
          "Käse (überbacken)",
        ],
      },
    ],
    spaghetti: [
      {
        name: "Spaghetti Arrabbiata",
        price: 9.5,
        id: 41,
        ingredients: ["Tomatensauce", "Knoblauch", "Scharf"],
        new: true,
      },
      {
        name: "Spaghetti al pesto",
        price: 9.5,
        id: 42,
        ingredients: ["Basilikum", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Spaghetti Bolognese",
        price: 9.5,
        id: 42,
        ingredients: ["Fleischsauce"],
      },
      {
        name: "Spaghetti Carbonara",
        price: 10.0,
        id: 43,
        ingredients: ["Schinken", "Ei", "Sahnesauce"],
      },
      {
        name: "Spaghetti alla Paesana",
        price: 10.0,
        id: 44,
        ingredients: [
          "Schinken",
          "Erbsen",
          "Champignons",
          "Tomaten- u. Sahnesauce",
        ],
      },
      {
        name: "Spaghetti Grecia",
        price: 11.0,
        id: 45,
        ingredients: [
          "Weichkäse",
          "Paprika",
          "Broccoli",
          "Knoblauch",
          "Sahnesauce",
        ],
      },
    ],
    rigatoni: [
      {
        name: "Rigatoni Contandina",
        price: 10,
        id: 60,
        ingredients: ["Schinken", "Champignons", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Rigatoni al Pesto",
        price: 9.5,
        id: 61,
        ingredients: ["Basilikum", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Rigatoni Bolognese",
        price: 9.5,
        id: 62,
        ingredients: ["Fleischsauce"],
      },
      {
        name: "Rigatoni alla Panna",
        price: 9.5,
        id: 63,
        ingredients: ["Schinken", "Sahnesauce"],
      },
      {
        name: "Rigatoni agli spinaci",
        price: 10,
        id: 64,
        ingredients: ["Schinken", "Spinat", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Rigatoni paesana",
        price: 10,
        id: 65,
        ingredients: [
          "Schinken",
          "Erbsen",
          "Champignons",
          "Tomaten- u. Sahnesauce",
        ],
      },
    ],
    tagliatelle: [
      {
        name: "Tagliatelle Contadina",
        price: 10,
        id: 70,
        ingredients: ["Schinken", "Champignons", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Tagliatelle al pesto",
        price: 9.5,
        id: 71,
        ingredients: ["Basilikum", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Tagliatelle alla panna",
        price: 9.5,
        id: 72,
        ingredients: ["Schinken", "Sahnesauce"],
      },
      {
        name: "Tagliatelle Mario",
        price: 12.0,
        id: 73,
        ingredients: [
          "Tomatensauce",
          "Rindfleischstreifen",
          "Zwiebeln",
          "Scharf",
        ],
        new: true,
      },
      {
        name: "Tagliatelle Grecia",
        price: 10.5,
        id: 74,
        ingredients: [
          "Weichkäse",
          "Paprika",
          "Broccoli",
          "Knoblauch",
          "Sahnesauce",
        ],
      },
      {
        name: "Tagliatelle Champignon",
        price: 10.5,
        id: 75,
        ingredients: ["Champignon-Tomatensauce", "Sahne", "Zwiebeln"],
        new: true,
      },
    ],
    tortellini: [
      {
        name: "Tortellini Contadina",
        price: 10.0,
        id: 80,
        ingredients: ["Schinken", "Champignons", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Tortellini Bolognese",
        price: 9.5,
        id: 81,
        ingredients: ["Fleischsauce"],
      },
      {
        name: "Tortellini alla panna",
        price: 9.5,
        id: 82,
        ingredients: ["Schinken", "Sahnesauce"],
      },
      {
        name: "Tortellini casa",
        price: 10.5,
        id: 83,
        ingredients: [
          "Broccoli",
          "Champignons",
          "Schinken",
          "Knoblauch",
          "Sahnesauce",
          "(pikant)",
        ],
      },
      {
        name: "Tortellini grecia",
        price: 10.5,
        id: 84,
        ingredients: [
          "Weichkäse",
          "Paprika",
          "Broccoli",
          "Knoblauch",
          "Sahnesauce",
        ],
      },
    ],
    diverse: [
      {
        name: "Pizzabrot",
        price: 6.0,
        id: 409,
        ingredients: ["Tomatensauce"],
      },
      {
        name: "Calamari Romana",
        price: 12.0,
        id: 410,
        ingredients: ["frittierte Tintenfischringe", "Knoblauchsauce"],
      },
      {
        name: "Schnitzel Wiener Art",
        price: 12.0,
        id: 411,
        ingredients: ["paniertes Schweineschnitzel", "Zitrone"],
      },
      {
        name: "Parmesanschnitzel",
        price: 13.0,
        id: 412,
        ingredients: ["mit Fleisch-Sahnesauce", "mit Käse überbacken"],
      },
      {
        name: "Schnitzel Casa",
        price: 12.0,
        id: 413,
        ingredients: [
          "mit Broccoli und Pilzen",
          "Tomaten-Sahnesauce",
          "mit Käse überbacken",
        ],
      },
      {
        name: "Rahmschnitzel",
        price: 13.0,
        id: 414,
        ingredients: ["mit Champignons und Rahmsauce"],
      },
      {
        name: "Zigeunerschnitzel",
        price: 13.0,
        id: 415,
        ingredients: [
          "mit Zwiebeln",
          "Paprika",
          "Champignons und Tomaten-Sahnesauce",
          "mit Käse überbacken",
        ],
      },
      {
        name: "Bologneseschnitzel",
        price: 13.0,
        id: 416,
        ingredients: [
          "mit Schinken",
          "Tomaten-Sahnesauce",
          "mit Käse überbacken",
        ],
      },
      {
        name: "Jägerschnitzel",
        price: 13.0,
        id: 418,
        ingredients: ["mit brauner Sauce", "Pilzen"],
      },
      {
        name: "Portion Pommes",
        price: 5.5,
        id: 419,
        ingredients: ["mit Ketchup"],
      },
    ],
    sandwiches: [
      {
        name: "Jumbo Hähnchen",
        price: 9.5,
        id: 50,
        ingredients: [
          "mit frittierter Hähnchenbrust",
          "Salat",
          "Tomaten",
          "Gurken",
          "Zwiebeln",
          "Dressing",
        ],
      },
      {
        name: "Jumbo Schnitzel",
        price: 9.5,
        id: 51,
        ingredients: [
          "mit frittiertem Schnitzel",
          "Salat",
          "Tomaten",
          "Gurken",
          "Zwiebeln",
          "Dressing",
        ],
      },
      {
        name: "Jumbo Tonno",
        price: 9.5,
        id: 52,
        ingredients: [
          "mit Thunfisch",
          "Ei",
          "Salat",
          "Tomaten",
          "Gurken",
          "Zwiebeln",
          "Dressing",
        ],
      },
    ],
  },
  drinks: [
    {
      name: "Coca Cola / Fanta (1,0 l)",
      price: 3.5,
      id: 90,
    },
    {
      name: "Mineralwasser (0,7 l)",
      price: 2.5,
      id: 91,
    },
    {
      name: "Flasche Bier (0,5 l)",
      price: 3.5,
      id: 92,
    },
    {
      name: "Red Bull (0,33 l)",
      price: 3.5,
      id: 93,
      new: true,
    },
    {
      name: "Ice Tea Pfirsich / Zitrone (0,5 l)",
      price: 3.5,
      id: 94,
      new: true,
    },
    {
      name: "Coca Cola / Zero / Fanta / Sprite (0,33 l)",
      price: 3.0,
      id: 95,
      new: true,
    },
    {
      name: "Flasche Rotwein / Weißwein (0,7 l)",
      price: 9,
      id: 96,
    },
  ],
};

export default menu;

// function renderMenu(menu) {
//   let output = "<html><body>\n";

//   // Render dishes
//   for (const [category, items] of Object.entries(menu.dishes)) {
//     output += `<h2>${category.toUpperCase()}</h2>\n`;

//     if (Array.isArray(items)) {
//       items.forEach((item, index) => {
//         output += `<div class="menu-item ${
//           index % 2 === 0 ? "even" : "odd"
//         }">\n`;
//         output += `  <p class="menu-item-name">${item.id}&nbsp;-&nbsp;${item.name}</p>&nbsp;`;
//         if (item.ingredients) {
//           output += `  <p class="menu-item-ingredients">${item.ingredients.join(
//             ", "
//           )}</p>`;
//         }
//         output += "</div>";
//       });
//     }
//   }

//   // Render drinks
//   output += `<h2>DRINKS</h2>\n`;
//   menu.drinks.forEach((item, index) => {
//     output += `<div class="menu-item ${index % 2 === 0 ? "even" : "odd"}">\n`;
//     output += `  <p><strong>${item.id}</strong> - <strong>${item.name}</strong></p>\n`;
//     if (item.ingredients) {
//       output += `  <p>Ingredients: ${item.ingredients.join(", ")}</p>\n`;
//     }
//     output += "</div>\n";
//   });

//   // Add styles
//   output += `<style>
//     @media screen, print {
//       p {
//         padding-top: 0;
//         padding-bottom: 0;
//         margin-top: 0;
//         margin-bottom: 0;
//       }
//       .menu-item {
//         margin-bottom: 10px;
//         display: flex;
//         align-items: center;
//         white-space: nowrap;
//         padding: 5px;
//         -webkit-print-color-adjust: exact !important;
//         print-color-adjust: exact !important;
//       }
//       .menu-item.odd {
//         background-color: #f5f5f5 !important;
//       }
//       .menu-item-name {
//         font-size: 23px;
//         font-weight: bold;
//         padding-right: 10px;
//         white-space: nowrap;
//       }
//       .menu-item-ingredients {
//         font-size: 23px;
//       }
//     }
//   </style>\n`;

//   output += "</body></html>";
//   console.log(output);
// }

// // Execute the function
// renderMenu(menu);
